export enum RouteType {
  Anonymous,
  Unauthenticated,
  Authenticated,
}

interface Route {
  path: string;
  type: RouteType;
}

const ROUTE_TABLE: Route[] = [
  {
    path: "/",
    type: RouteType.Authenticated,
  },
  {
    path: "/404",
    type: RouteType.Anonymous,
  },
  {
    path: "/callback",
    type: RouteType.Unauthenticated,
  },
];

export const getRouteType = (path: string): RouteType => {
  const routes = ROUTE_TABLE.filter(
    (x) => x.path.toLowerCase() === path.toLowerCase()
  );
  return routes.length == 1 ? routes[0].type : RouteType.Authenticated;
};
