import "@/styles/globals.css";
import "@/config/axiosConfig";

import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import type { ReactCookieProps } from "react-cookie";
import type { ToastContainerProps } from "react-toastify";

import { MetadataProvider } from "@/layouts/MetadataProvider";
import type { MsalLayoutProps } from "@/layouts/MsalLayout";
import type { ReduxStoreProps } from "@/layouts/ReduxStore";
import { getRouteType, RouteType } from "@/util/Routes";

const MW = {
  AppInsights: dynamic(() => import("@/layouts/AppInsights")),
  CookiesProvider: dynamic<ReactCookieProps>(() =>
    import("react-cookie").then((module) => module.CookiesProvider)
  ),
  MsalLayout: dynamic<MsalLayoutProps>(() =>
    import("@/layouts/MsalLayout").then((module) => module.default)
  ),
  ReduxStore: dynamic<ReduxStoreProps>(() =>
    import("@/layouts/ReduxStore").then((module) => module.default)
  ),
  ToastContainer: dynamic<ToastContainerProps>(() =>
    import("react-toastify").then((module) => module.ToastContainer)
  ),
};

const MyApp = (props: AppProps) => {
  const { Component, pageProps } = props;
  const router = useRouter();
  const routeType = getRouteType(router.pathname);

  return (
    <>
      <MetadataProvider appProps={props} router={router} />
      <MW.ReduxStore>
        <MW.CookiesProvider>
          <MW.AppInsights>
            <MW.ToastContainer />
            {routeType == RouteType.Anonymous ? (
              <Component {...pageProps} />
            ) : (
              <MW.MsalLayout
                routeType={routeType}
                Component={Component}
                pageProps={pageProps}
              />
            )}
          </MW.AppInsights>
        </MW.CookiesProvider>
      </MW.ReduxStore>
    </>
  );
};

export default MyApp;
