import axios, { AxiosError } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { AcquireB2CToken } from "@/util/auth";

const refreshAuth = async (error: AxiosError) => {
  delete error.response?.config.headers!["Authorization"];
  await AcquireB2CToken();
  return Promise.resolve();
};

axios.defaults.baseURL = process.env.NEXT_PUBLIC_FORK_BASEURL;
axios.defaults.headers.common["Content-Type"] = "application/json";

createAuthRefreshInterceptor(axios, refreshAuth);
