import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import axios from "axios";

import { loginRequest, msalConfig } from "@/config/authConfig";
import { AppDispatch, reduxStore } from "@/layouts/ReduxStore";
import { clearB2CAuthority, setB2CAuthority } from "@/slice/UserSlice";

import { GetB2CAuthority, UserTypes } from "./b2c";

export const msalInstance = new PublicClientApplication(msalConfig);

export const HandleLogin = async (
  dispatch: AppDispatch,
  userType: UserTypes
) => {
  dispatch(setB2CAuthority(userType));
  try {
    return await msalInstance.loginRedirect({
      ...loginRequest,
      authority: GetB2CAuthority(userType),
    });
  } catch (err) {
    console.error("layout token acq failed", err);
  }
};

export const AcquireB2CToken = async () => {
  const authority = reduxStore.getState().user.B2CAuthority;
  if (!authority) {
    return;
  }

  return await msalInstance
    .acquireTokenSilent({
      authority,
      scopes: loginRequest.scopes,
    })
    .then((authenticationResult) => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authenticationResult.idToken}`;
      return authenticationResult;
    })
    .catch(async (error) => {
      if (!(error instanceof InteractionRequiredAuthError)) {
        return Promise.reject(error);
      }
      await msalInstance
        .acquireTokenRedirect({
          ...loginRequest,
          authority,
          prompt: "select_account",
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    });
};

export const LogoutRedirect = async (
  authority: string,
  dispatch: ThunkDispatch<any, undefined, AnyAction>
) => {
  const account = msalInstance.getActiveAccount();
  msalInstance.setActiveAccount(null);
  dispatch(clearB2CAuthority());
  await msalInstance.logoutRedirect({ account: account, authority });
};
