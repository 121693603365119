export enum UserTypes {
  Staff,
  E2E,
}

export const GetB2CAuthority = (userType: UserTypes) => {
  const userFlow =
    userType == UserTypes.Staff
      ? "B2C_1_SynerAIInternal"
      : userType == UserTypes.E2E
      ? "B2C_1_SynerAIE2ETest"
      : "";
  return `https://syneraicustomers.b2clogin.com/syneraicustomers.onmicrosoft.com/${userFlow}`;
};
