import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";

import { BaseUrl } from "@/util/app";

export const msalConfig = {
  auth: {
    clientId: "e648e096-9480-4b59-8542-06d1741a57f4",
    knownAuthorities: ["https://syneraicustomers.b2clogin.com"],
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: BaseUrl,
    redirectUri: "/callback",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      iframeHashTimeout: 10000,
      loggerCallback: (level: LogLevel, message: any, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  prompt: "select_account",
  scopes: [
    `https://syneraicustomers.onmicrosoft.com/${msalConfig.auth.clientId}/user_impersonation`,
  ],
};
