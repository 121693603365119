import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GetB2CAuthority, UserTypes } from "@/util/b2c";

export interface UserSlice {
  B2CAuthority: string;
  isStaff: boolean;
}

const initialState: UserSlice = {
  B2CAuthority: "",
  isStaff: false,
};

export const UserSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    clearB2CAuthority: (state) => {
      state.isStaff = false;
      state.B2CAuthority = "";
    },
    setB2CAuthority: (state, action: PayloadAction<UserTypes>) => {
      state.isStaff = action.payload == UserTypes.Staff;
      state.B2CAuthority = GetB2CAuthority(action.payload);
    },
  },
});

export const { setB2CAuthority, clearB2CAuthority } = UserSlice.actions;
export default UserSlice.reducer;
