import type { AppProps } from "next/app";
import Head from "next/head";
import { NextRouter } from "next/router";
import { jsonLdScriptProps } from "react-schemaorg";
import type { AnalysisNewsArticle, WithContext } from "schema-dts";

import { ServicePage } from "@/model/page";
import { BaseUrl } from "@/util/app";

interface Props {
  appProps: AppProps;
  router: NextRouter;
}

const defaultTitle = "Fork by SynerAI";

export const MetadataProvider = ({ appProps, router }: Props) => {
  const { Component, pageProps } = appProps;
  const page = Component as ServicePage;
  if (!page.getMetadata) {
    return <></>;
  }
  const { pageTitle, pageDescription, pageThumbnail, pageUrl } =
    page.getMetadata(pageProps);
  const title = !!pageTitle ? pageTitle({ pageProps, router }) : defaultTitle;
  const url = !!pageUrl ? pageUrl({ pageProps, router }) : "";
  const description = !!pageDescription
    ? pageDescription({ pageProps, router })
    : "";
  const thumbnail = !!pageThumbnail ? pageThumbnail({ pageProps, router }) : "";

  return (
    <Head key="metatdata-provider">
      <meta property="og:type" content="website" />
      <meta property="twitter:domain" content={BaseUrl} />

      {!!title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}

      {!!description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}

      {!!description && (
        <>
          <meta property="og:url" content={url} />
          <meta property="twitter:url" content={url} />
        </>
      )}

      {!!thumbnail && (
        <>
          <meta property="og:image" content={thumbnail} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={thumbnail} />
        </>
      )}
    </Head>
  );
};
